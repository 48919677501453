import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'

import Layout from '@components/layout'

const HealthAndEnvironmentalPage = () => {
  return (
    <Layout pageTitle="Safety &amp; Environmental">
      <Container fluid="xxl">
        <Row>
          <Col className="no-gutters">
            <div className="hero-banner large-hero">
              <StaticImage
                className="hero-image"
                src="../../media/safety-environmental-hero.png"
                alt=""
                aspectRatio={3.75 / 1}
                layout="fullWidth"
              />
              <div className="hero-fade"></div>
              <div className="hero-text">
                <h2>Services</h2>
                <h1>Safety and Environmental</h1>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <main>
        <Container fluid="xxl" className="section text-center">
          <Row>
            <Col>
              <h3>
                Everyone gets home. It's not just a promise, its our way. Wessuc is a family, our families and the environment are always our priority.
              </h3>
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl"><hr/></Container>
        <Container className="section default-text">
          <Row md={2} xs={1}>
            <Col md={{ order: 'first' }} xs={{ order: 'last' }}>
              <StaticImage
                src="../../media/Wessuc-COR.jpg"
                alt="COR certification sticker on the tailgate of a white pickup truck"
              />
            </Col>
            <Col>
              <h3>IHSA COR Certified</h3>
              <p>
                Wessuc has obtained its Certificate of Recognition (COR™) through IHSA, an elite designation in health and safety compliance.
              </p>
              <p>
                COR™ is aimed at driving positive workplace behaviour and practices that lead to improved safety performance. It is often required for contracts with both public- and private-sector construction projects in Ontario. Some municipalities expect their contractors to be registered with the COR™ program to qualify for bidding purposes. There are 19 elements of the COR™ audit tool including: hazard analysis, safe work practices, preventative maintenance, emergency preparedness, and workplace inspections.
              </p>
            </Col>
          </Row>
        </Container>
        <Container className="section default-text">
          <Row md={2} xs={1}>
            <Col>
              <h3>Confined Space Training</h3>
              <p>
                Any space with restricted access is considered a confined space. From storage tanks to digesters, silos, sewers, wells, access shafts, and more, only trained personnel are permitted to enter these spaces.
              </p>
              <p>
                The dangers of a confined space include:
                <ul>
                  <li>Oxygen deficiency</li>
                  <li>Toxic atmosphere</li>
                  <li>Falling hazard</li>
                  <li>Engulfment</li>
                  <li>Accessibility</li>
                  <li>Explosive gases</li>
                </ul>
              </p>
            </Col>
            <Col>
              <StaticImage
                src="../../media/Wessuc-Dewatering-13.jpg"
                alt="Construction worker in full PPE enters a confined space"
              />
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl"><hr/></Container>
        <Container className="section text-center">
          <Row>
            <Col>
              <h3>
                Wessuc proudly leads the way in environmentally responsible solutions, turning waste into value.
              </h3>
              <p>
                Wessuc crews are specially trained to work in confined spaces and know how to identify the hazards involved. They plan out entry to every confined space and they are ready to react to unforeseen circumstances. Crews are outfitted with the proper PPE. Our extensive knowledge of these hazards, along with the tools required to address them, enable us to clean out challenging confined spaces in a safe and efficient manner.
              </p>
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl"><hr/></Container>
        <Container className="section">
          <Row md={2} xs={1}>
            <Col>
              <p>
                Wessuc Crews have also been trained in:
                <ul>
                  <li>Confined space entry</li>
                  <li>First Aid</li>
                  <li>WHMIS</li>
                  <li>TDG</li>
                  <li>EUSA</li>
                  <li>Fall Arrest</li>
                  <li>OHSA</li>
                  <li>OTM Book 7 (Work Zone Safety)</li>
                </ul>
              </p>
            </Col>
            <Col>
              <StaticImage
                src="../../media/fall-protection-safety.jpg"
                alt="worker in confined space with two safety clips securing him to a railing"
              />
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl"><hr/></Container>
        <Container className="section text-center">
          <Row md={3} xs={1}>
            <Col>
                <Button href='/our-approach' variant='primary' className="shadow">International Safety<br/>Network (ISN)</Button>
            </Col>
            <Col>
                <Button href='/our-approach' variant='primary' className="shadow">Infrastructure Health <br/>and Safety Association (IHSA)</Button>
            </Col>
            <Col>
                <Button href='/our-approach' variant='primary' className="shadow">Technology And Our <br/>Approach</Button>
            </Col>
          </Row>
        </Container>
      </main>
    </Layout>
  )
}

export default HealthAndEnvironmentalPage